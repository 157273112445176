import React from 'react';

const HomePage = () => {

  return (
    <>
      Initial Commit
    </>
  );
};

export default HomePage;
